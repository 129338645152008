
import { defineComponent, Ref, ref } from 'vue';
import {
    listPagination, ListHeaderItem, RequestData
} from '@/components/common/list';
import addButton from '@/components/common/add-button/index.vue';
import firmwareModel, { FirmwareForm } from '@/components/view/super/firmware-dialog';

const headers: Array<ListHeaderItem> = [{
    name: 'Version',
    label: WordList.TabelUpdateBoxVersion
}, {
    name: 'Model',
    label: WordList.TabelVersionModel
}, {
    name: 'Log',
    label: WordList.TabelVersionLog,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];

const searchKeyList = [{
    label: WordList.TabelUpdateBoxVersion,
    name: 'Version'
}, {
    label: WordList.TabelVersionModel,
    name: 'Model'
}];

export default defineComponent({
    components: {
        listPagination,
        addButton,
        firmwareModel
    },
    setup() {
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'getversion',
            param: {
                searchKey: 'Version',
                searchValue: ''
            }
        });

        const isShowFirmwareModel = ref(false);
        const initForm: Ref<FirmwareForm> = ref({
            Log: '',
            Version: '',
            Url: '',
            ManageID: '',
            Manage: '',
            AllManage: ''
        });
        const operateType: Ref <OperateType> = ref('add');

        const add = () => {
            initForm.value = {
                Log: '',
                Version: '',
                Url: '',
                ManageID: [],
                Manage: '',
                AllManage: '0'
            };
            operateType.value = 'add';
            isShowFirmwareModel.value = true;
        };

        const edit = (listItem: FirmwareForm) => {
            const formData = { ...listItem, Manage: '' };
            // formData.AllManage = formData.AllManage === '1';
            formData.ManageID = (formData.ManageID as string).split(';');
            initForm.value = formData;
            operateType.value = 'edit';
            isShowFirmwareModel.value = true;
        };
        return {
            headers,
            searchKeyList,
            updateToList,
            listRequestData,
            isShowFirmwareModel,
            add,
            edit,
            initForm,
            operateType
        };
    }
});
