
import dialogShell from '@/components/common/dialog-shell/index.vue';
import HttpRequest from '@/util/axios.config';
import {
    defineComponent, PropType, reactive, Ref, ref
} from 'vue';
import distributorTable from '@/components/view/super/firmware-dialog/distributorTable.vue';
import { FirmwareForm } from './all.type';
import { controlDisTable } from './util';

export default defineComponent({
    components: {
        dialogShell,
        distributorTable
    },
    props: {
        initForm: {
            type: Object as PropType<FirmwareForm>,
            required: true
        },
        operateType: {
            type: String,
            default: 'add'
        }
    },
    setup(props, { emit }) {
        const {
            tempDisAccount,
            disAccount,
            searchAccount,
            isRequiredChooseTips,
            checkChooseItem
        } = controlDisTable();

        const rules = {
            Log: [
                { required: true, message: WordList.RuleLogEmpty, trigger: 'blur' },
                { max: 1000, message: WordList.RuleCommonLess1000, trigger: 'blur' }
            ],
            Version: [
                { required: true, message: WordList.RuleVersionEmpty, trigger: 'blur' }
            ],
            Url: [
                { required: true, message: WordList.RuleUrlEmpty, trigger: 'blur' }
            ]
        };

        const firmwareDialog: Ref<any> = ref();
        const formData = reactive({ ...props.initForm });
        const close = () => {
            emit('close');
        };

        const submit = () => {
            const params = { ...formData };
            params.ManageID = (params.ManageID as Array<string>).join(';');
            // 检查是否勾选dis
            checkChooseItem(params);
            firmwareDialog.value.validate((valid: boolean) => {
                if (valid) {
                    // 防止未勾选dis依旧提交请求
                    if (isRequiredChooseTips.value) return;
                    if (params.AllManage === '1') params.ManageID = '';
                    HttpRequest.post((props.operateType === 'add' ? 'addversion' : 'modifyversion'), params, () => {
                        emit('success');
                        close();
                    });
                }
            });
        };

        const updateSelectData = (val: Array<string>) => {
            formData.ManageID = val;
        };

        return {
            formData,
            submit,
            close,
            firmwareDialog,
            rules,
            updateSelectData,
            tempDisAccount,
            disAccount,
            searchAccount,
            isRequiredChooseTips
        };
    }
});

