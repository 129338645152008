import { ref } from 'vue';

// 通过账号搜索dis
const controlDisTable = () => {
    // input保存临时变量
    const tempDisAccount = ref('');
    // 最后传值给distable的变量
    const disAccount = ref('');

    const searchAccount = () => {
        disAccount.value = tempDisAccount.value;
    };

    // 检查是否勾选dis
    const isRequiredChooseTips = ref(false);
    const checkChooseItem = (formData: {
        ManageID: string | string[];
        AllManage: string | boolean;
    }) => {
        // 勾选其中任何一个dis或者全选，视为正确
        if (formData.ManageID !== '' || formData.AllManage === '1') {
            isRequiredChooseTips.value = false;
        } else {
            isRequiredChooseTips.value = true;
        }
    };

    return {
        tempDisAccount,
        disAccount,
        searchAccount,
        isRequiredChooseTips,
        checkChooseItem
    };
};

export default null;
export {
    controlDisTable
};